<template>
  <div>
    <v-app>
      <v-dialog
        v-model="dialog2"
        max-width="700px"
        style="height: 100%"
        persistent
        scrollable
      >
        <v-card class="elevation-5">
          <v-card-text>
            <v-container>
              <v-row>
                 <v-col md="12">
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:25px; sans-serif;color:white;border:none;text-align:center;'
              >
                <strong
                  >Kişisel Verilerin Korunması Hakkında Hekim Aydınlatma
                  Metni</strong
                >
              </p>
              <p
                style='margin-top:1cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:white;border:none;text-align:justify;'
              >
                NER Medikal Araştırma Danışmanlık&nbsp;Ltd. Şti. (<span
                  dir="RTL"
                  style=' serif;'
                  >&ldquo;</span
                ><strong>NER</strong>&rdquo;) olarak kişisel verilerinizin
                g&uuml;venliğine ve mahremiyetine olduk&ccedil;a &ouml;nem
                veriyor ve bu verilerinizi işlemek ve muhafaza etmek i&ccedil;in
                m&uuml;mk&uuml;n olan en &uuml;st seviyede g&uuml;venlik
                tedbirlerini almaya &ccedil;alışıyoruz.
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:white;border:none;text-align:justify;'
              >
                Bu bilin&ccedil;le işbu Kişisel Verilerin Korunması Hakkında
                Hekim Aydınlatma Metni (<span
                  dir="RTL"
                  style=' serif;'
                  >&ldquo;</span
                ><strong>Aydınlatma Metni</strong>&rdquo;), kimliği belirli veya
                belirlenebilir ger&ccedil;ek kişiye ilişkin her t&uuml;rl&uuml;
                bilgiyi ifade eden kişisel verilerinizin 6698 Sayılı Kişisel
                Verilerin Korunması Kanunu<span
                  dir="RTL"
                  style=' serif;'
                  >&rsquo;</span
                >na (<span dir="RTL" style=' serif;'
                  >&ldquo;</span
                ><strong>Kanun</strong>&rdquo;) ve
                <span style="color:white;"
                  >hukuki dayanağını Kanun</span
                ><span
                  dir="RTL"
                  style=' serif;color:white;'
                  >&rsquo;</span
                ><span style="color:white;"
                  >dan alan ikincil mevzuat ile Kişisel Verileri Koruma Kurulu
                  kararlarına (Hepsi birlikte&nbsp;</span
                ><span
                  dir="RTL"
                  style=' serif;color:white;'
                  >&ldquo;</span
                ><strong
                  ><span style="color:white;"
                    >Veri Koruma Mevzuatı</span
                  ></strong
                ><span style="color:white;"
                  >&rdquo; olarak anılacaktır.) uygun olarak&nbsp;</span
                >NER&nbsp;<span style="color:white;"
                  >tarafından&nbsp;</span
                ><span 
                  >işlenme s&uuml;re&ccedil;lerine ilişkin olarak tarafınızı
                  bilgilendirmek &uuml;zere hazırlanmıştır.&nbsp;</span
                >
              </p>
              <div
                style='margin:0cm;font-size:16px; serif;border:none;'
              >
                <ol style="margin-bottom:0cm;list-style-type: decimal;">
                  <li
                    style='margin:0cm;font-size:16px; serif;border:none;'
                  >
                    <strong
                      ><span style="color:white;"
                        >Veri Sorumlusunun Kimliği</span
                      ></strong
                    ><span style="color:white;">&nbsp;</span>
                  </li>
                </ol>
              </div>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:white;border:none;text-align:justify;'
              >
                <strong
                  ><span
                    >Veri Sorumlusu Kimliğ</span
                  ></strong
                ><strong><span style="color:white;">i:</span></strong> NER
                Medikal Araştırma Danışmanlık&nbsp;Ltd. Şti.&nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:white;border:none;'
              >
                <strong>Adres:</strong> Esentepe Mah. B&uuml;y&uuml;kdere Cad.
                No:195 Kat:5 34394 Levent, Şişli,&nbsp;İstanbul&nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:white;border:none;text-align:justify;'
              >
                <strong>Web Sitesi: </strong>
                <a href="http://www.ner-consulting.com" target="_blank"
                  ><span style="color:#0563C1;text-decoration:underline;"
                    ><span style="color: white;"
                      ><span style="color: white;"
                        >www.ner-consulting.com</span
                      ></span
                    ></span
                  ></a
                >&nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                <strong>Telefon Numarası:</strong> +90 212 355 89 65
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                <strong>E-Posta: </strong>
                <a href="mailto:info@ner-consulting.com"
                  ><span style="color:#0563C1;text-decoration:underline;"
                    ><span style="color: white;"
                      ><span style="color: white;"
                        >info@ner-consulting.com</span
                      ></span
                    ></span
                  ></a
                >&nbsp;
              </p>
              <br />
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <div
                style='margin:0cm;font-size:16px; serif;border:none;'
              >
                <ol
                  start="2"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li
                    style='margin:0cm;font-size:16px; serif;border:none;'
                  >
                    <strong>İş</strong
                    ><strong>lenen Kişisel Verileriniz, Toplanma Y</strong
                    ><strong>&ouml;ntemi ve Hukuki Sebebi</strong>
                  </li>
                </ol>
              </div>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Tarafınızdan veya &ccedil;alışmasını
                y&uuml;r&uuml;tt&uuml;ğ&uuml;n&uuml;z Dernek/Kurum/Sponsor
                tarafından aktarılan kimlik ve iletişim bilgilerinizden oluşan
                kişisel verileriniz (isim, soy isim, telefon numarası, e-posta
                adresi, &ccedil;alıştığınız kurum/hastane bilgisi, uygulama
                yeriniz, biriminiz, iş unvanınız, aktif olduğunuz tıbbi alan,
                mesleki nitelikleriniz, uzmanlık bilgileriniz ve bilimsel
                faaliyetleriniz gibi profesyonel ayrıntılar (&ouml;nceki klinik
                araştırma deneyimi ve ge&ccedil;miş veya bekleyen
                araştırma&nbsp;&ccedil;alışmalarına katılım gibi),
                MedicalResearch.Network sistemlerimizde bulunan hekim kullanıcı
                kaydı işlemleri sırasında ilgili bilgilerin doldurulması ve
                bizim ile ger&ccedil;ekleştirdiğiniz sair iletişim kapsamında;
                e-posta, telefon, diğer &ccedil;evrimi&ccedil;i ve/veya
                &ccedil;evrimdışı elektronik iletişim platformları, barındırma
                firmaları, iş birliği i&ccedil;inde olduğumuz
                dernekler/kurumlar/sponsorlar ve bunların entegre sistemleri,
                yetkili kamu kurumları ve&nbsp;&ouml;zel kuruluşlar ve bunların
                entegre sistemleri ile bunlara ileride eklenebilecek sair
                y&ouml;ntemler (kanallar) &nbsp;aracılığıyla kısmen veya tamamen
                otomatik olan yollarla elde edilmekte ve işlenmektedir.
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Yetkili kamu kurumları tarafından talep edilmesi sebebi ile
                toplanan kişisel verilerinizi hukuki
                y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;m&uuml;z&uuml; yerine
                getirmek i&ccedil;in zorunlu olması hukuki sebebi ile,
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Dernek/Kurum/Sponsor ile aramızda klinik
                araştırma/g&ouml;zlemsel &ccedil;alışma ve sağlık alanındaki
                &ccedil;alışmalara ilişkin s&ouml;zleşmelere dayalı bir ilişki
                olması sebebi ile toplanan kişisel verilerinizi bir
                s&ouml;zleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
                olması kaydına dayanan, s&ouml;zleşmenin taraflarına ait kişisel
                verilerin işlenmesinin gerekli olması hukuki sebebi ile,
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                MedicalResearch.Network sistemlerimizde bulunan hekim kullanıcı
                kaydı işlemleri ve tarafınızla yapacağımız sair iletişim kapsamı
                nedeni ile toplanan kişisel verilerinizi meşru menfaatimizin
                bulunması hukuki sebebi ile, işleyeceğiz.
              </p>
              <div
                style='margin:0cm;font-size:16px; serif;border:none;'
              >
                <ol
                  start="3"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li
                    style='margin:0cm;font-size:16px; serif;border:none;'
                  >
                    <strong
                      >Kişisel Verilerinizin İşlenmesinin
                      Ama&ccedil;ları</strong
                    >
                  </li>
                </ol>
              </div>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                NER, kişisel verilerinizi aşağıda belirtilen ama&ccedil;lar ile
                işleyecektir:
              </p>
              <ul style="list-style-type: disc;">
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Sağlık alanında araştırma yapabilmeniz (tıbbi cihaz,
                  ila&ccedil;, v.b.),
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Elektronik olgu rapor formu (e-ORF) yazılımının
                  kullanılabilmesi,
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  MedicalResearch.Network sistemlerimize giriş yapabilmeniz ve
                  bu sistemleri kullanabilmeniz,
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Size bu kapsamda bilgilendirme yapabilmemiz,
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Mevzuat uyarınca ilgili kamu kurum ve kuruluşları ile edinilen
                  bilgileri paylaşma ve yanıt verme,
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  D&uuml;zenleyici ve denetleyici kurumlarla, resmi mercilerin
                  talep ve denetimleri doğrultusunda gerekli bilgilerin temini,
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  İlgili kişi olarak kullanacağınız haklarınıza ilişkin
                  taleplerin karşılanması.
                </li>
              </ul>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <div
                style='margin:0cm;font-size:16px; serif;border:none;'
              >
                <ol
                  start="4"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li
                    style='margin:0cm;font-size:16px; serif;border:none;'
                  >
                    <strong
                      >Kişisel Verilerin Aktarıldığı Taraflar ve Aktarım
                      Ama&ccedil;ları&nbsp;</strong
                    >
                  </li>
                </ol>
              </div>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                NER b&uuml;nyesinde kişisel verilerinize, yalnızca yukarıda
                detaylarına yer verilen ama&ccedil;lar bakımından sınırlı yetki
                erişimine ve gizlilik y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;ne
                sahip &ccedil;alışanlarımız ve hizmet sağlayıcılarımız
                tarafından kendi g&ouml;revlerini yerine getirmek amacıyla
                gerekli&nbsp;&ouml;l&ccedil;&uuml;de erişilebilmektedir.
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Kişisel verileriniz Veri Koruma Mevzuatı kapsamında gerekli
                raporlamaların yapılması yahut yetkili kamu kurum ve
                kuruluşların talebi halinde ilgili kişi ve kuruluşlara NER<span
                  dir="RTL"
                  style=' serif;'
                  >&rsquo;</span
                >in faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve denetlenmesi
                i&ccedil;in kanuni y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;m&uuml;z
                &ccedil;er&ccedil;evesinde aktarılabilecektir.
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Kişisel verileriniz, e-posta veri depolama hizmetlerimiz dahil
                olmak &uuml;zere kullanacağınız Medical Research.Network
                sistemlerimizin ve kimi e-posta hizmeti sunucularının yurt
                dışında bulunması sebepleriyle iş faaliyetlerimizin
                y&uuml;r&uuml;t&uuml;lmesi i&ccedil;in a&ccedil;ık rızanızın
                bulunması halinde yurt dışına aktarılabilecektir. Tarafımızla
                paylaştığınız kişisel verileriniz,&nbsp;&ouml;zellikle Microsoft
                Outlook, Gmail ve benzeri yazılımların kullanılması halinde yurt
                dışına aktarılabilir. Kişisel verileriniz, NER ile sunucu ve
                veri tabanları yurt dışında yer alan bu gibi yazılımlar yoluyla
                aktarmanız halinde kendiliğinden yurt dışına aktarılmış
                olacaktır. NER<span
                  dir="RTL"
                  style=' serif;'
                  >&rsquo;</span
                >in faaliyetlerinin s&uuml;rd&uuml;r&uuml;lmesi ve hukuki
                y&uuml;k&uuml;ml&uuml;l&uuml;klerinin yerine getirilmesi
                amacıyla Microsoft Outlook, Gmail, gibi sunucular ve veri
                tabanları yurt dışında yer alan yazılımları kullanılabilir,
                fakat kişisel verileriniz bu haller haricinde yurt dışına
                aktarılmaz.&nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Bu konuda rızanızı her zaman bu Aydınlatma Metni<span
                  dir="RTL"
                  style=' serif;'
                  >&rsquo;</span
                >nde belirtilen Veri Sorumlusu/NER iletişim adreslerinde yer
                alan adreslerden yazılı talepte bulunarak geri
                &ccedil;ekebilirsiniz.
              </p>
              <div
                style='margin:0cm;font-size:16px; serif;border:none;'
              >
                <ol
                  start="5"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li
                    style='margin:0cm;font-size:16px; serif;border:none;'
                  >
                    <strong>Veri Saklama S&uuml;releri</strong>
                  </li>
                </ol>
              </div>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Kişisel verileriniz, yukarıda yer alan işleme ama&ccedil;ları
                ile bağlantılı, sınırlı ve&nbsp;&ouml;l&ccedil;&uuml;l&uuml;
                olarak, ilgili mevzuat uyarınca işlemenin gerekli olduğu
                s&uuml;reler boyunca idari ve teknik tedbirler alınarak
                saklanır. Veri işleme faaliyetinin altındaki işleme amacı son
                bulan verileriniz NER tarafından Kişisel Verilerin Silinmesi,
                Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında
                Y&ouml;netmelik uyarınca imha edilir.&nbsp;
              </p>
              <div
                style='margin:0cm;font-size:16px; serif;border:none;'
              >
                <ol
                  start="6"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li
                    style='margin:0cm;font-size:16px; serif;border:none;'
                  >
                    <strong
                      ><span style="color:white;"
                        >Kişisel Verilerinize İlişkin Haklarınız</span
                      ></strong
                    ><span style="color:white;">&nbsp;</span
                    ><strong>ve Bunları Kullanma Y</strong
                    ><strong>&ouml;ntemi</strong>
                  </li>
                </ol>
              </div>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Kişisel veri sahibi&nbsp;olarak,&nbsp;Kanun<span
                  dir="RTL"
                  style=' serif;'
                  >&rsquo;</span
                >un 11. maddesindeki&nbsp;haklarınıza ilişkin taleplerinizi
                belirtmek ve kişisel verileriniz &uuml;zerindeki haklarınızı
                kullanmak&nbsp;amacıyla&nbsp;veya vermiş olduğunuz rızanızı geri
                almak istediğinizde&nbsp;bu talebinizi yukarıdaki a&ccedil;ık
                adresimize bizzat teslim edebilir, anılmış e-postaya
                g&uuml;venli elektronik imzalı olarak veya diğer suretlerde
                kimliğinizi ve ilginizi ispatlamak yoluyla iletebilir, noter
                kanalıyla g&ouml;nderebilirsiniz. Belirlenen y&ouml;ntemlerle
                taleplerinizi iletmeniz
                durumunda,&nbsp;talebiniz&nbsp;niteliğine g&ouml;re talebi en
                kısa s&uuml;rede ve en ge&ccedil; otuz g&uuml;n i&ccedil;inde
                sonu&ccedil;landırılacaktır.&nbsp;Kanun<span
                  dir="RTL"
                  style=' serif;'
                  >&rsquo;</span
                >un 11. maddesi kapsamında aşağıdaki haklara sahipsiniz:&nbsp;
              </p>
              <ol start="1" style="list-style-type: lower-alpha;">
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Kişisel verilerinizin işlenip işlenmediğini
                  &ouml;ğrenme,&nbsp;
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Kişisel verileriniz işlenmişse buna ilişkin bilgi talep
                  etme,&nbsp;
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Kişisel verilerinizin işlenme amacını ve bunların amacına
                  uygun kullanılıp kullanılmadığını &ouml;ğrenme,&nbsp;
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Yurt i&ccedil;inde veya yurt dışında kişisel verilerinizin
                  aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,&nbsp;
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                  h&acirc;linde bunların d&uuml;zeltilmesini isteme,&nbsp;
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Kişisel verilerinizin silinmesini veya yok edilmesini
                  isteme,&nbsp;
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                  h&acirc;linde bunların d&uuml;zeltilmesine ve/veya kişisel
                  verilerinizin silinmesini veya yok edilmesine ilişkin
                  işlemlerin kişisel verilerinizin aktarıldığı
                  &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini
                  isteme,&nbsp;
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  İşlenen verilerinizin m&uuml;nhasıran otomatik sistemler
                  vasıtasıyla analiz edilmesi suretiyle ilgili kişi aleyhine bir
                  sonucun ortaya &ccedil;ıkmasına itiraz etme,&nbsp;
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle
                  zarara uğramanız h&acirc;linde zararın giderilmesini talep
                  etme.
                </li>
              </ol>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Bu hakların mutlak olmadığını ve yukarıda a&ccedil;ıklanan veri
                sahibi haklarıyla ilgili olarak aşağıda belirtildiği gibi
                bir&ccedil;ok istisna, muafiyet, kısıtlama ve yeterlilikler
                bulunduğuna dikkat ediniz:
              </p>
              <ul style="list-style-type: disc;margin-left:26px;">
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  NER talebin sizin tarafınızdan veya adınıza yapıldığını
                  doğrulamak i&ccedil;in kimlik belgeleri isteyebilir;
                </li>
                <li
                  style='margin:0cm;font-size:16px; serif;border:none;'
                >
                  NER aşırı ve/veya a&ccedil;ık&ccedil;a mesnetsiz kabul edilen
                  bir talep doğrultusunda hareket etmeyi reddedebilir <em>(</em
                  ><em
                    >&ouml;rneğin m&uuml;kerrer taleplerde bulunulması
                    halinde</em
                  >). NER<span dir="RTL" style=' serif;'
                    >&rsquo;</span
                  >in yine de b&ouml;yle bir talebe istinaden harekete
                  ge&ccedil;mek i&ccedil;in hazırlık yapması gerekiyorsa Veri
                  Koruma Mevzuatı&rsquo;nın belirlediği sınırlar dahilinde makul
                  bir &uuml;cret yansıtabilir.
                </li>
              </ul>
              <p
                style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:36.0pt;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Veri sahibi haklarıyla ilgili diğer belirli istisnalar ve
                sınırlandırmalar bulunmaktadır. NER, gelen talebin ardından ve
                duruma uygun olduğunu d&uuml;ş&uuml;nd&uuml;ğ&uuml; takdirde her
                t&uuml;rl&uuml; istisnayı, muafiyeti, kısıtlamayı ve yeterliliği
                sizinle g&ouml;r&uuml;şmeye gayret edecektir.&nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                <br />
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                <strong>Kişisel Verilerin Yurt Dışına Aktarım Onayı</strong>
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                Kişisel verilerimin NER tarafından iş faaliyetlerinin
                y&uuml;r&uuml;t&uuml;lmesi ve denetlenmesi i&ccedil;in gerekli
                olması nedeniyle kullanmakta olduğu global veri işleme
                sistemlerine, hizmetlerinden faydalanılan yurt dışında yerleşik
                yahut veri tabanları yurt dışında bulunan hizmet
                sağlayıcılarının veya bulut hizmet sağlayıcılarının veri
                tabanlarına<em
                  >&nbsp;(Microsoft Outlook, Gmail, e-ORF, Hosting ve
                  benzeri</em
                >) kaydedilmek ve işlenmek &uuml;zere yurt dışına aktarılmasını
                kabul ediyorum. A&ccedil;ık rızamı her zaman geri
                &ccedil;ekebileceğimi ve bu ama&ccedil;la NER ile iletişime
                ge&ccedil;ebileceğimi anladım.
              </p>
              <p
                style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color: white;border:none;text-align:justify;'
              >
                &nbsp;
              </p>
            

            </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-row>
                  <v-col md="12" >
                    <div class="form-group">
                      <label class="checkbox mb-0" v-if="checkKvkk">
                        <input
                          type="checkbox"
                          name="agree"
                          @click="disabled = !disabled"
                          v-bind:false-value="0"
                          v-bind:true-value="1"
                        />
                        <span class="mr-2"></span>
                        Hüküm ve koşulları kabul ediyorum
                        <a href="#" class="ml-2"></a>.
                      </label>
                    </div>
                  </v-col>
                  <v-col md="6">
                    <button
                      type="button"
                      class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      @click="canceldialog"
                    >
                      İptal
                    </button>
                  </v-col>
                  <v-col md="6" v-if="checkKvkk">
                    <button
                      type="button"
                      :disabled="disabled"
                      class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      @click="sendKvkkConfirm"
                    >
                      Onayla
                    </button>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- dialog buraya kadar -->
      <div class="d-flex flex-column flex-root">
        <div
          class="login login-1 d-flex flex-column flex-md-row flex-sm-row flex-column-fluid bg-white"
          :class="{
            'login-signin-on': this.state == 'signin',
            'login-signup-on': this.state == 'signup',
            'login-forgot-on': this.state == 'forgot',
            'login-reset-on': this.state == 'reset'
          }"
          id="kt_login"
        >
          <!--begin::Aside-->
          <div
            class="login-aside d-flex flex-column flex-row-auto"
            style="background-color: #442941"
          >
            <div class="aside-img d-flex flex-row-fluid bgi-no-repeat">
              <img src="media/logos/default.png" class="m-auto w-50" />
            </div>
          </div>
          <!--begin::Aside-->
          <!--begin::Content-->
          <div
            class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
          >
            <div class="d-flex flex-column-fluid flex-center">
              <!--begin::Signin-->
              <div class="login-form login-signin">
                <form
                  class="form"
                  novalidate="novalidate"
                  id="kt_login_signin_form"
                  ref="kt_login_signin_form"
                >
                  <div class="mb-6 pt-lg-0 pt-5 w-25">
                    <h1
                      class="font-weight-bolder text-dark ml-auto"
                      style="font-size: 3rem"
                    >
                      MEDICAL RESEARCH NETWORK
                    </h1>
                  </div>
                  <div class="form-group">
                    <div
                      id="example-input-group-1"
                      label=""
                      label-for="example-input-1"
                    >
                      <input
                        class="form-control form-control-solid"
                        type="text"
                        name="email"
                        ref="email"
                        v-model="form.email"
                        placeholder="Kullanıcı Adı"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div
                      id="example-input-group-2"
                      label=""
                      label-for="example-input-2"
                    >
                      <input
                        class="form-control form-control-solid"
                        type="password"
                        name="password"
                        ref="password"
                        v-model="form.password"
                        placeholder="Şifre"
                      />
                    </div>
                  </div>
                  <div class="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      ref="kt_login_signin_submit"
                      class="btn btn-primary px-15 py-2 my-3"
                      id="btnLogin"
                    >
                      Giriş
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary py-2 my-3"
                      @click="showForm('forgot')"
                    >
                      Şifremi Unuttum
                    </button>
                  </div>
                </form>
              </div>
              <!--end::Signin-->
              <!--begin::Signup-->
              <div class="login-form login-signup">
                <form
                  class="form"
                  novalidate="novalidate"
                  id="kt_login_signup_form"
                >
                  <div class="pb-13 pt-lg-0 pt-5">
                    <h3
                      class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                    >
                      Kaydol
                    </h3>
                    <p class="text-muted font-weight-bold font-size-h4">
                      Hesap oluşturmak için bilgilerinizi giriniz
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control form-control-solid"
                      type="text"
                      placeholder="Fullname"
                      name="fullname"
                      ref="fullname"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control form-control-solid"
                      type="email"
                      placeholder="Email"
                      name="email"
                      ref="remail"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control form-control-solid"
                      type="password"
                      placeholder="Password"
                      name="password"
                      ref="rpassword"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control form-control-solid"
                      type="password"
                      placeholder="Confirm password"
                      name="cpassword"
                      ref="cpassword"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group">
                    <label class="checkbox mb-0">
                      <input type="checkbox" name="agree" />
                      <span class="mr-2"></span>
                      Hüküm ve koşulları kabul ediyorum
                      <a href="#" class="ml-2"></a>.
                    </label>
                  </div>
                  <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                    <button
                      ref="kt_login_signup_submit"
                      class="btn btn-primary px-8 py-4 my-3 mr-4"
                    >
                      Kayıt
                    </button>
                    <button
                      type="button"
                      id="kt_login_signup_cancel"
                      class="btn btn-light-primary px-8 py-4 my-3"
                      @click="showForm('signin')"
                    >
                      İptal
                    </button>
                  </div>
                </form>
              </div>
              <!--end::Signup-->
              <!--begin::Forgot-->
              <div class="login-form login-forgot">
                <!--begin::Form-->
                <form
                  class="form"
                  novalidate="novalidate"
                  id="kt_login_forgot_form"
                  ref="kt_login_forgot_form"
                >
                  <div class="pt-lg-0 pt-5">
                    <p class="text-muted font-weight-bold font-size-h4">
                      Şifrenizi sıfırlamak için Email adresinizi giriniz
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control form-control-solid"
                      type="email"
                      placeholder="Email"
                      name="email"
                      autocomplete="off"
                      v-model="email"
                    />
                  </div>
                  <div class="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      type="button"
                      id="kt_login_forgot_submit"
                      class="btn btn-primary  px-15 py-2 my-3"
                      @click="forgotPassword()"
                    >
                      Gönder
                    </button>
                    <button
                      type="button"
                      id="kt_login_forgot_cancel"
                      class="btn btn-secondary py-2 my-3"
                      @click="showForm('signin')"
                    >
                      İptal
                    </button>
                  </div>
                </form>
              </div>
              <!--end::Forgot-->
              <!-- begin resetpassword -->
              <div class="login-form login-reset">
                <!--begin::Form-->
                <form
                  class="form"
                  novalidate="novalidate"
                  id="kt_login_reset_form"
                  ref="kt_login_reset_form"
                >
                  <div class="pt-lg-0 pt-5">
                    <p class="text-muted font-weight-bold font-size-h5">
                      Şifrenizi sıfırlamak için mail adresinize gelen tek
                      kullanımlık şifreyi giriniz
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control form-control-solid"
                      type="code"
                      placeholder="Kod"
                      name="code"
                      value=" "
                      autocomplete="off"
                      v-model="OTP"
                    />
                  </div>

                  <div class="pt-lg-0 pt-5">
                    <p class="text-muted font-weight-bold font-size-h6">
                      Lütfen oluşturmak istediğiniz şifreyi giriniz
                    </p>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control form-control-solid"
                      type="password"
                      placeholder="Şifre"
                      name="password"
                      value=" "
                      autocomplete="off"
                      v-model="PASSWORD"
                    />
                  </div>

                  <!-- buraya kadar -->
                  <div class="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      type="button"
                      id="kt_login_reset_submit"
                      class="btn btn-primary px-15 py-2 my-3"
                      @click="changePassword"
                    >
                      Oluştur
                    </button>
                    <button
                      type="button"
                      id="kt_login_reset_cancel"
                      class="btn btn-secondary py-2 my-3"
                      @click="showForm('signin')"
                    >
                      İptal
                    </button>
                  </div>
                </form>
              </div>
              <!-- end resetpassword -->
            </div>
            <!--begin::Content footer-->
            <div
              class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
            >
                <a
                @click="open()"
                  class="text-primary ml-10 font-weight-bold"
                  style="color: #b9a9b9 !important; font-size: 13px"
                  >Aydınlatma Metni</a
                >
              <a
                href="http://ner-consulting.com/"
                class="text-primary ml-10 font-weight-bold"
                style="color: #b9a9b9 !important; font-size: 13px"
                >Hakkımızda</a
              >
            </div>
            <!--end::Content footer-->
          </div>
          <!--end::Content-->
        </div>
      </div>
    </v-app>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
@import "@/global.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import DisableAutocomplete from '@aacassandra/vue-disable-autocomplete';
import Vue from "vue";
Vue.use(DisableAutocomplete);
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import ApiService from "@/core/services/api.service";

export default {
  name: "login-1",
  data() {
    return {
      checkKvkk:true,
      lastKvkk: "",
      disabled: true,
      dialog2: false,
      userid: "",
      email: "",
      OTP: "",
      PASSWORD: "",
      kvkkControl: "",
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      rules: {
        required: [val => (val || "").length > 0 || "Bu alan doldurulmalı"]
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    const reset_form = KTUtil.getById("kt_login_reset_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Lütfen Kullanıcı Adınızı Giriniz."
            },
            stringLength: {
              min: 7,
              max: 254,
              message: "Kullanıcı Adınız 7 Karakterden Fazla Olmalıdır"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Lütfen Şifrenizi Giriniz."
            },
            stringLength: {
              min: 6,
              max: 30,
              message: "Şifreniz 6 Karakterden Fazla Olmalıdır."
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Lütfen email adresinizi giriniz."
            },
            emailAddress: {
              message: "Lütfen geçerli bir email adresi giriniz."
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv3 = formValidation(reset_form, {
      fields: {
        code: {
          validators: {
            notEmpty: {
              message: "Tek Kullanımlık Şifreyi Giriniz"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Oluşturmak İstediğiniz Yeni Şifreyi Giriniz"
            },

            stringLength: {
              min: 6,
              max: 30,
              message: "Şifreniz 6 Karakterden Fazla Olmalıdır."
            },
            regexp: {
              regexp: /^[A-Za-z0-9]/,
              message:
                "Oluşturmak istediğiniz şifre özel karakter içermemelidir."
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      document.getElementById('btnLogin').disabled = true;
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        
        // send login request
        //console.log('login')
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(data => {
            //console.log("tamam OKKK");
            //console.log("sonuclar burada", data);

            this.kvkkControl = data.kvkkCheck;
            this.userid = data.id;
            if (this.kvkkControl == "False") {
              //console.log("kvkk ife girdi");
              this.dialog2 = true;
            } else {
              Swal.fire({
                icon: "success",
                title: "Giriş Başarılı Yönlendiriliyorsunuz",
                showConfirmButton: false,
                timer: 1000
              });
              setTimeout(() => {
                this.$router.push({ name: "workslist" });
              }, 1000);
            }

            // console.log("kvkk ", this.kvkkControl);
          })
          .catch(response => {
            //console.log('dış Err : ', err)
            document.getElementById('btnLogin').disabled = false;
            console.log("error geldi", response);
            Swal.fire({
              title: "",
              text: "Geçersiz Kullanıcı Adı veya Şifre Girdiniz",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false
            });
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Lütfen Kullanıcı adı veya şifrenizi kontrol ediniz.",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, {
            email: email,
            password: password
          })
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });

    this.fv3.on("core.form.valid", () => {
      this.changePassword();
    });

    this.fv3.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Lütfen Girdiğiniz Bilgileriniz Kontrol Ediniz",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
    this.getLastversionKvkk();
  },
  methods: {
    onCheckboxClicked: function(newValue) {
      console.log(newValue);

      if (!this.checkbox) {
        this.checkbox = true;
      } else {
        this.checkbox = false;
      }
      console.log(this.checkbox);
    },
    open(){
      console.log("denmeme");
      this.checkKvkk=false;
      this.dialog2=true;
    },
    showForm(form) {
      this.state = form;
      //  console.log("form", form);
      //var form_name = "kt_login_" + form + "_form";
      // KTUtil.animateClass(
      //   KTUtil.getById(form_name),
      //   "animate__animated animate__backInUp"
      // );
    },
    forgotPassword() {
      let body = { email: this.email };

      console.log("email burada", body);
      ApiService.post("/forgotpassword", body)
        .then(({ data }) => {
          console.log("password bilgileri", data);
          this.$router.push({ name: "reset" });
        })
        .catch(err => {
          console.log("error", err);
          Swal.fire({
            title: "",
            text: "İşlem Başarısız",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
    },
    changePassword() {
      // var passw=  /^[A-Za-z]/;
      // if(this.PASSWORD.match(passw))
      // {
      //   console.log("Eşleşti");
      // }
      // else{
      //     return  Swal.fire({
      //       title: "",
      //       text: "Oluşturmak istediğiniz şifre noktalama işaretleri içermemelidir.",
      //       icon: "error",
      //       confirmButtonClass: "btn btn-secondary",
      //       heightAuto: false,
      //     });
      // }
      let body = { OTP: this.OTP, PASSWORD: this.PASSWORD };
      console.log("body", body);
      ApiService.post("/otpcheck", body)
        .then(({ data }) => {
          console.log("yeni şifre bilgileri", data);
          Swal.fire({
            icon: "success",
            title: "Şifre Değiştirme İşlemi Başarılı Yönlendiriliyorsunuz",
            showConfirmButton: false,
            timer: 2000
          });
          setTimeout(() => {
            this.showForm("signin");
          }, 2000);
        })
        .catch(err => {
          console.log("error", err);
          Swal.fire({
            title: "",
            text: "Lütfen Girdiğiniz Bilgileriniz Kontrol Ediniz",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
    },
    canceldialog() {
      this.dialog2 = false;
    },
    sendKvkkConfirm() {
      this.dialog2 = false;
      let body = { version: this.lastKvkk };
      ApiService.put("user/" + this.userid + "/kvkkversion", body)
        .then(() => {
          
          //console.log("kvkk onay ne geldi", data);
          Swal.fire({
            icon: "success",
            title: "Giriş Başarılı Yönlendiriliyorsunuz",
            showConfirmButton: false,
            timer: 1200
          });
          this.$router.push({ name: "workslist" });
        })
        .catch(() => {
          // console.log("error", err);
        });
    },
    getLastversionKvkk() {
      ApiService.get("lastkvkkversion")
        .then(({ data }) => {
          this.lastKvkk = data.result;
          //console.log("last versiyonu tuttuk", this.lastKvkk);
        })
        .catch(() => {
          //console.log("error", err);
        });
    }
  }
};
</script>
